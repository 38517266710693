import { format, parseISO } from "date-fns";
import locale from 'date-fns/esm/locale/es/index.js';
var moment = require('moment');

export const obtenerFechaFormateada = (value) => {
  if (!value) return "";
  const fechaString = value;
  const fecha = parseISO(fechaString);
  const fechaFormateada = format(fecha, "EEEE d 'de' MMMM 'de' yyyy", { locale });

  return fechaFormateada;
}

export const obtenerFechaDMA = (value) => {
  const fechaFormateada = moment(value).format("DD/MM/YYYY");

  return fechaFormateada;
}


export const convertirFormatoHora = (hora) => {
  if (hora) {
    const partes = hora?.split(':');
    const horaFormateada = partes[0] + ':' + partes[1];
    return horaFormateada;
  }


}

export const obtenerFechaYHora = (value) => {
  const fechaFormateada = moment(value).format("DD/MM/YYYY HH:ss");

  return fechaFormateada;
}

export const obtenerHora = (value) => {
  const fechaFormateada = moment(value).format("HH:ss");

  return fechaFormateada;
}

export const convertirFormatoFechaHora = (fechaString?) => {
  if (fechaString) {
    // Si la entrada es una cadena, la parseamos
    var fecha = typeof fechaString === 'string' ? new Date(fechaString) : fechaString;

    // Verificamos si la fecha es válida
    if (isNaN(fecha.getTime())) {
      // Si no es una fecha válida, devolvemos un mensaje de error
      return 'Formato de fecha inválido';
    }

    // Obtenemos los componentes de la fecha y hora
    var dia = fecha.getDate();
    var mes = fecha.getMonth() + 1; // Los meses son indexados desde 0
    var año = fecha.getFullYear();
    var horas = fecha.getHours();
    var minutos = fecha.getMinutes();

    // Aseguramos que los componentes tengan dos dígitos
    dia = dia < 10 ? '0' + dia : dia;
    mes = mes < 10 ? '0' + mes : mes;
    horas = horas < 10 ? '0' + horas : horas;
    minutos = minutos < 10 ? '0' + minutos : minutos;

    // Formateamos la cadena de salida
    var formatoFinal = dia + '/' + mes + '/' + año + ' ' + horas + ':' + minutos;

    return formatoFinal;
  }
}


