import { createSelector } from 'reselect';

const selectRaw = (state) => state.users.list;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const getRolesAndClienCompany = (arra) => {
	return arra.map(({ firstName,	lastName,	dni,	email,	createdAt,	clientCompany }) => ({
	  companyName: clientCompany	?	clientCompany.companyName	:	"no-data",
	  firstName,
	  lastName,
	  dni,
	  email,
	  createdAt,
	}));
  };

const	selectRowsCsv	=	createSelector([selectRaw],	({rows})	=>	{	
	const columnsHeader =  [
		{id:"companyName", displayName:  "Exportador"},
		{id:"dni", displayName:  "DNI"},
		{id:"firstName", displayName:  "Nombre"},
		{id:"lastName", displayName:  "Apellido"},
		{id:"email", displayName:  "Email"},
		{id:"createdAt", displayName:  "Fecha de Alta"},
	  ]

	const data  = getRolesAndClienCompany(rows);

	return	[columnsHeader,	data];
});

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);

const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const selectOrderBy = createSelector([selectRaw], (raw) => {
	const sorter = raw.sorter;

	if (!sorter) {
		return null;
	}

	if (!sorter.field) {
		return null;
	}

	let direction = sorter.order === 'desc' ? 'DESC' : 'ASC';

	return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
	return raw.filter;
});

const selectRawFilter = createSelector([selectRaw], (raw) => {
	return raw.rawFilter;
});

const selectLimit = createSelector([selectRaw], (raw) => {
	const pagination = raw.pagination;
	return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
	const pagination = raw.pagination;

	if (!pagination || !pagination.pageSize) {
		return 0;
	}

	const current = pagination.current || 1;

	return current - 1;
});

const selectPagination = createSelector([selectRaw, selectCount], (raw, count) => {
	return {
		...raw.pagination,
		total: count,
	};
});

const selectFilterPreview = createSelector([selectRaw], (raw) => raw.filterPreview)


const usersListSelectors = {
	selectLoading,
	selectRows,
	selectRowsCsv,
	selectCount,
	selectHasRows,
	selectSorter,
	selectRawFilter,
	selectOrderBy,
	selectLimit,
	selectFilter,
	selectOffset,
	selectPagination,
	selectFilterPreview
};

export default usersListSelectors;
