import Errors from "src/modules/shared/error/errors";
import service from "../GeneralService";
import generalSelectors from "src/modules/shared/generals/list/GeneralListSelectors";

const prefix = "GENERAL";

const generalActions = {
  GET_VESSEL_PHASES_STARTED: `${prefix}_GET_VESSEL_PHASE_STARTED`,
  GET_VESSEL_PHASES_SUCCESS: `${prefix}_GET_VESSEL_PHASE_SUCCESS`,
  GET_VESSEL_PHASES_ERROR: `${prefix}_GET_VESSEL_PHASE_ERROR`,

  FETCH_STAKEHOLDERS_NOT_PAGINATION: `${prefix}_FETCH_STAKEHOLDERS_NOT_PAGINATION`,

  CLIENT_COMPANIES_NOT_PAGINATION_STARTED: `${prefix}_CLIENT_COMPANIES_NOT_PAGINATION_STARTED`,
  CLIENT_COMPANIES_NOT_PAGINATION_SUCCESS: `${prefix}_CLIENT_COMPANIES_NOT_PAGINATION_SUCCESS`,
  CLIENT_COMPANIES_NOT_PAGINATION_ERROR: `${prefix}_CLIENT_COMPANIES_NOT_PAGINATION_ERROR`,

  ORDERS_NOT_PAGINATION: `${prefix}_ORDERS_NOT_PAGINATION`,

  CHANGE_ORDER_FILTER: `${prefix}_CHANGE_ORDER_FILTER`,

  //MERCADERIA
  GET_COMMODITY: `${prefix}_GET_COMMODITY_`,
  GET_COMMODITY_OPTIONS: `${prefix}_GET_COMMODITY_OPTIONS`,

  //SEARCH BOOKING
  FETCH_BOOKING_STARTED: `${prefix}_FETCH_BOOKING_STARTED`,
  GET_BOOKING_NAVIS: `${prefix}_GET_BOOKING_NAVIS`,
  ERROR_GET_BOOKING_NAVIS: `${prefix}_ERROR_GET_BOOKING_NAVIS`,

  //FILTROS

  GET_FILTERPREVIEW: `${prefix}_GET_FILTERPREVIEW`,
  GET_FILTERPREVIEW_OPERATION: `${prefix}_GET_FILTERPREVIEW_OPERATION`,
  GET_FILTERPREVIEW_CC: `${prefix}_GET_FILTERPREVIEW_CC`,
  GET_FILTERPREVIEW_BILL: `${prefix}_GET_FILTERPREVIEW_BILL`,
  GET_FILTERPREVIEW_RECEIPT: `${prefix}_GET_FILTERPREVIEW_RECEIPT`,

  getClientCompaniesNotPagination: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: generalActions.CLIENT_COMPANIES_NOT_PAGINATION_STARTED,
      });
      const response = await service.getclientCompanies(
        generalSelectors.selectFilter(getState()),
      );
      dispatch({
        type: generalActions.CLIENT_COMPANIES_NOT_PAGINATION_SUCCESS,
        payload: response,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: generalActions.CLIENT_COMPANIES_NOT_PAGINATION_ERROR,
      });
    }
  },

  getOrdersNotPagination: () => async (dispatch, getState) => {
    try {
      const response = await service.getOrders(
        generalSelectors.selectOrderBy(getState()),
        9999999,
        generalSelectors.selectOffset(getState()),
        generalSelectors.selectFilterOrder(getState()),
      );
      dispatch({
        type: generalActions.ORDERS_NOT_PAGINATION,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
    }
  },

  doChangeOrderFilterNotPagination: (values?) => async (dispatch) => {
    dispatch({
      type: generalActions.CHANGE_ORDER_FILTER,
      payload: values,
    });

    dispatch(generalActions.getOrdersNotPagination());
  },

  getStakeholdersNotPagination: () => async (dispatch, getState) => {
    try {
      const response = await service.getStakeholdersNotPagination(
        generalSelectors.selectOrderBy(getState()),
        9999999,
        generalSelectors.selectOffset(getState()),
        generalSelectors.selectFilterOrder(getState()),
      );
      dispatch({
        type: generalActions.FETCH_STAKEHOLDERS_NOT_PAGINATION,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
    }
  },

  getVesselPhases: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: generalActions.GET_VESSEL_PHASES_STARTED,
      });

      const response = await service.getVesselPhases();
      dispatch({
        type: generalActions.GET_VESSEL_PHASES_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: generalActions.GET_VESSEL_PHASES_ERROR,
      });
    }
  },

  //Mercaderia

  getCommodity: () => async (dispatch) => {
    try {
      const response = await service.getAllCommodity();
      dispatch({
        type: generalActions.GET_COMMODITY,
        payload: response,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },

  getCommodityOptions: () => async (dispatch) => {
    try {
      const response = await service.getAllCommodity();
      dispatch({
        type: generalActions.GET_COMMODITY_OPTIONS,
        payload: response,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },

  //SEARCH BOOKING

  searchBooking: (data, message?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: generalActions.FETCH_BOOKING_STARTED,
      });
      console.warn(data);
      const response = await service.searchBooking(data);
      if (!response.data) {
        dispatch({
          type: generalActions.GET_BOOKING_NAVIS,
          payload: {},
        });
      } else {
        dispatch({
          type: generalActions.GET_BOOKING_NAVIS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: generalActions.ERROR_GET_BOOKING_NAVIS,
      });
      Boolean(message) && Errors.showMessage(error);
    }
  },

  //OBTENER ITEMS DE LOS FILTROS
  FilterPreviewReceipt: (values) => async (dispatch) => {
    try {
      dispatch({
        type: generalActions.GET_FILTERPREVIEW_RECEIPT,
        payload: values,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },

  FilterPreviewCC: (values) => async (dispatch) => {
    try {
      dispatch({
        type: generalActions.GET_FILTERPREVIEW_CC,
        payload: values,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },

  FilterPreviewBilling: (values) => async (dispatch) => {
    try {
      dispatch({
        type: generalActions.GET_FILTERPREVIEW_BILL,
        payload: values,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },
  FilterPreview: (values) => async (dispatch) => {
    try {
      dispatch({
        type: generalActions.GET_FILTERPREVIEW,
        payload: values,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },
  FilterPreviewOperation: (values) => async (dispatch) => {
    try {
      dispatch({
        type: generalActions.GET_FILTERPREVIEW_OPERATION,
        payload: values,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },
};

export default generalActions;
