import React from 'react';

interface CustomSVGProps {
  color?: string;
  width?: string;
  height?: string;
}

const ContainerSvgIcon: React.FC<CustomSVGProps> = ({
  color = '#ffffff',
  width = '40px',
  height = '40px',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M3 19H21M3 5H21M4 5V19M20 5V19M8 8.5V15.5M16 8.5V15.5M12 8.5V15.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContainerSvgIcon;
