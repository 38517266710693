import { createSelector } from "reselect";

const selectRaw = (state) => state.general2.list;

const selectorClientCompanies = createSelector(
  [selectRaw],
  (raw) => raw.companies,
);
const selectLoadingCompanies = createSelector(
  [selectRaw],
  (raw) => raw.loadingCompanies,
);
const selectCompaniesValueId = createSelector(
  [selectorClientCompanies],
  ({ data }) =>
    !Boolean(data)
      ? []
      : data
          .map(({ cuit, companyName, id }) => ({
            value: id,
            label: companyName,
            id: id,
            cuit: cuit.replace(/ /g, ""),
          }))
          .sort((a, b) => a.label?.localeCompare(b.label)),
);
const selectCompaniesValueCuit = createSelector(
  [selectorClientCompanies],
  ({ data }) =>
    !Boolean(data)
      ? []
      : data
          .map(({ cuit, companyName, id }) => ({
            value: cuit,
            label: companyName,
            id: id,
            cuit: cuit.replace(/ /g, ""),
          }))
          .sort((a, b) => a.label?.localeCompare(b.label)),
);

const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});
const selectFilterOrder = createSelector([selectRaw], (raw) => {
  return raw.orderFilter;
});
const selectVesselPhases = createSelector([selectRaw], (raw) => {
  return raw.vesselPhases;
});
const selectStakeholdersModified = createSelector(
  [selectRaw],
  ({ stakeholders }) => {
    let modifiedStakeholders = [] as any;

    if (Boolean(stakeholders)) {
      modifiedStakeholders = stakeholders.map(({ id, companyName }) => ({
        value: id,
        label: companyName,
      }));
    }

    modifiedStakeholders.unshift({
      value: true,
      label: "Sin despachante asignado",
    });

    return modifiedStakeholders.sort((a, b) =>
      a.label?.localeCompare(b.label || ""),
    );
  },
);
//Mercaderia
const selectCommodity = createSelector([selectRaw], (raw) => raw.commodity);
const selectoCommodityOptions = createSelector([selectCommodity], (data) =>
  !Boolean(data)
    ? []
    : data.map(({ id, navisId }) => ({ value: id, label: navisId })),
);

// Orders
const selectorOrdersNotPagination = createSelector(
  [selectRaw],
  (raw) => raw.ordersNotPagination,
);
const selectorOrdersNotPaginationObj = createSelector(
  [selectorOrdersNotPagination],
  (order) =>
    order.map(({ value }) => ({
      value,
      label: value,
    })),
);

//SEARCH BOOKING
const selectorBookingNavis = createSelector(
  [selectRaw],
  (raw) => raw.booking || {},
);
const selectorHazards = createSelector([selectorBookingNavis], (booking) => {
  const createSelect = ({ imdg, imgd_class, un }) => ({
    value: `${imdg} / ${un} / ${imgd_class}`,
    label: `${imdg} / ${un} / ${imgd_class}`,
  });

  if (!Array.isArray(booking)) return booking?.hazard?.map(createSelect);

  return booking
    ?.map(({ hazard }) => hazard)
    .flat()
    .map(createSelect);
});
const selectErrorNavis = createSelector([selectRaw], (raw) => raw.bookingError);

//FILTROS

const selectFilterPreview = createSelector(
  [selectRaw],
  (raw) => raw.filterPreview,
);

const selectFilterOperationPreview = createSelector(
  [selectRaw],
  (raw) => raw.filterPreviewOperation,
);

const selectFilterPreviewBilling = createSelector(
  [selectRaw],
  (raw) => raw.filterPreviewBilling,
);
const selectFilterPreviewCC = createSelector(
  [selectRaw],
  (raw) => raw.filterPreviewCC,
);
const selectFilterPreviewReceipt = createSelector(
  [selectRaw],
  (raw) => raw.filterPreviewReceipt,
);

const selectBookingLoading = createSelector(
  [selectRaw],
  (raw) => raw.loadingBooking,
);

const generalListSelectors = {
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectCompaniesValueId,
  selectCompaniesValueCuit,
  selectFilterOrder,
  selectVesselPhases,
  selectStakeholdersModified,
  selectCommodity,
  selectoCommodityOptions,
  selectorOrdersNotPaginationObj,
  selectorBookingNavis,
  selectErrorNavis,
  selectFilterPreviewCC,
  selectFilterPreviewBilling,
  selectFilterPreviewReceipt,
  selectorOrdersNotPagination,
  selectLoadingCompanies,
  selectFilterPreview,
  selectorHazards,
  selectBookingLoading,
  selectFilterOperationPreview,
};

export default generalListSelectors;
