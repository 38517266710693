import { createSelector } from "reselect";
import { obtenerFechaDMA } from "src/view/turns/FormatFunctionDates";

const selectRaw = (state) => state.scheduleOperations.list;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const getTurno  = (date,outOfRange,from,to)  =>  {
  if(date){
    let fechaFormateada = obtenerFechaDMA(date);
    return  fechaFormateada + ' - ' + (outOfRange ? 'Otro Horario' : from.slice(0, 5) + ' - ' + to.slice(0, 5));
  }

  return "no-data";
}

const	getTurnosOperacion	=	(arra)	=>	{

  if(arra.length !== 0){
    if(arra[0].fullHeader){
      return	arra.map(({fullHeader, cntNbr,  transportation,  reservation, emptyHeader})	=>	({
        name: fullHeader	?	fullHeader.operation.name	:	"no-data",
        companyName:	fullHeader	?	fullHeader.clientCompany.companyName	:	emptyHeader.clientCompany.companyName,
        bookingNumber:  fullHeader	?	fullHeader.bookingNumber	:	emptyHeader.bookingNumber,
        cntNbr,
        transportation: transportation ?  transportation  : "Camion",
        approved: reservation && reservation.approved  ? "Aprobado" : "Desaprobado",
        turno: reservation && reservation.turn  ? getTurno(reservation.date, reservation.outOfRange,  reservation.turn.from,  reservation.turn.to) : getTurno(reservation.date, reservation.outOfRange,  null,  null),
      }));
    }else {
      return	arra.map(({transportation,  reservation, emptyHeader,  type, carrierDni, truckPatent,  semiPatent})	=>	({
        name: emptyHeader ? emptyHeader.operation.name  : "no-data",
        type,
        companyName:	emptyHeader ? emptyHeader.clientCompany.companyName : "no-data",
        bookingNumber:  emptyHeader ? emptyHeader.bookingNumber : "no-data",
        transportation: transportation ?  transportation  : "Camion",
        carrierDni,
        truckPatent,
        semiPatent,       
        approved: reservation && reservation.approved  ? "Aprobado" : "Desaprobado",
        turno: reservation && reservation.turn  ? getTurno(reservation.date, reservation.outOfRange,  reservation.turn.from,  reservation.turn.to) : getTurno(reservation.date, reservation.outOfRange,  null,  null),
        numberOfContainers: emptyHeader ? emptyHeader.numberOfContainers : "no-data"
      }));
    }
  }
}

const selectRowsCsv = createSelector([selectRaw], ({rows}) => {

  let data: Array<any>  = [];
  let columnsHeader:  Array<any> = [];

  if(rows.length  !==  0){
    if(rows[0].fullHeader){
      columnsHeader =  [
        {id:"name", displayName:  "Tipo Operación"},
        {id:"companyName", displayName:  "Exportador"},
        {id:"bookingNumber", displayName:  "Booking"},
        {id:"cntNbr", displayName:  "Número de Contenedor"},
        {id:"transportation", displayName:  "Tipo de Transporte"},
        {id:"approved", displayName:  "Estado"},
        {id:"turno", displayName:  "Turno"},
      ]
  
      data  = getTurnosOperacion(rows);
    }
    
    if(rows[0].emptyHeader) {
      columnsHeader =  [
        {id:"name", displayName:  "Tipo Operación"},
        {id:"type", displayName:  "Tipo de Contenedor"},
        {id:"companyName", displayName:  "Exportador"},
        {id:"bookingNumber", displayName:  "Booking"},
        {id:"transportation", displayName:  "Tipo de Transporte"},
        {id:"carrierDni", displayName:  "Dni Chofer"},
        {id:"truckPatent", displayName:  "Patente Camion"},
        {id:"semiPatent", displayName:  "Patente Semi"},
        {id:"approved", displayName:  "Estado"},
        {id:"turno", displayName:  "Turno"},
        {id:"numberOfContainers", displayName:  "Contenedores Totales	"},
      ]
        
      data  = getTurnosOperacion(rows);
    }
  }
  
  return	[data,  columnsHeader];
});

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);

const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectCurrentTab = createSelector([selectRaw], ({ currentTab }) => {
  return currentTab;
});

const selectRawFilter = createSelector([selectRaw], (raw) => {
  return raw.rawFilter;
});

const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    };
  }
);
const selectFetchParam = createSelector(
  [selectFilter, selectOrderBy, selectOffset, selectLimit],
  (filter, orderBy, offset, limit) => ({
    sort: orderBy,
    size: limit,
    page: offset,
    filter: filter,
  })
);

const selectFilterLabel = createSelector([selectRaw], (raw) => {
  return raw.filterLabel;
});
const scheduledOperationsListSelectors = {
  selectLoading,
  selectRows,
  selectRowsCsv,
  selectCount,
  selectHasRows,
  selectSorter,
  selectRawFilter,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectFetchParam,
  selectCurrentTab,
  selectFilterLabel
};

export default scheduledOperationsListSelectors;
